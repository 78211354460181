.fullWidth {
    width: 100%;
}

.halfTransparent {
    opacity: 0.5;
}

.relative {
    position: relative;
}

.downloadButton {
    position: absolute;
    top: 0;
    right: 0;
}
